/* eslint-disable no-undef */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Imports Material Design
import "../../assets/mdbreact/scss/mdb-free.scss";
import "bootstrap-css-only/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import {CookieNotice} from "gatsby-cookie-notice";

import ModalInteresse from "../../components/ModalInteresse/ModalInteresse";
import Avisos from "../../components/Headers/Avisos/Avisos";
import MainNavbar from "../../components/Headers/MainNavbar/MainNavbar";
import MainFooter from "../../components/MainFooter/MainFooter";

import "../MasterLayout/Layout.css";

export default function Layout({ children }) {
	Layout.propTypes = {
		children: PropTypes.object.isRequired,
	};

	useEffect(() => {
		if (
			process.env.NODE_ENV === "production" &&
			typeof window !== "undefined"
		) {
			// HandTalk
			let script = document.createElement("script");
			script.src = "//plugin.handtalk.me/web/latest/handtalk.min.js";
			script.async = true;
			script.onload = () => {
				new window.HT({
					token: "67d8ad24f158ff8b093ac95c14fb6b67",
					maxTextSize: 1000,
					pageSpeech: true,
				});
			};
			document.body.appendChild(script);

			// RD Station
			script = document.createElement("script");
			script.src =
				"https://d335luupugsy2.cloudfront.net/js/loader-scripts/168bf134-2e44-4e66-ab9a-cdb7f59837c2-loader.js";
			script.async = true;
			document.body.appendChild(script);

			/*

			// Suiteshare
			(function (s, u, i, t, e) {
				let share = s.createElement("script");
				share.async = true;
				share.id = "suiteshare";
				share.src = "https://static.suiteshare.com/widgets.js";
				share.setAttribute("init", i);
				s.head.appendChild(share);
			})(document, "script", "ef7845d350ca952280bfaf054cba8fca0ba37696");
            */
		}
	});

	return (
		<ModalInteresse>
			<Avisos />

			<CookieNotice
        backgroundClasses={"avisoCookies"}        
        buttonWrapperClasses={"btnAvisoCookies"}
        acceptButtonClasses={"btnAcceptCookies"}
        declineButtonClasses={"btnDeclineCookies"}
        acceptButtonText={"Eu aceito"}
        declineButtonText={"Recusar"}
        personalizeButtonEnable={false}
        cookies={[
                {name: 'necessary', editable: false, default: true, title: 'Essentiel', text: 'Essential cookies are necessary for the proper functioning of the site. The site cannot function properly without them.' },
                {name: 'gatsby-gdpr-google-tagmanager', editable: true, default: true, title: 'Google Analytics', text: 'Google Analytics is a statistical tool of Google allowing to measure the audience of the website.' },
                {name: 'gatsby-gdpr-facebook-pixel', editable: true, default: true, title: 'Meta Pixel', text: 'Meta Pixel is a statistical tool of Meta allowing to measure the audience of the website.' },
          ]}>
          <p className="textoAvisoCookies">Utilizamos tecnologias como cookies para melhorar a sua experiência em nossos sites, personalizar a oferta de cursos e 
            serviços, e recomendar conteúdos de seu interesse. Para mais informações sobre a coleta e uso de dados pessoais, 
            acesse a nossa <a href="https://www.feevale.br/Comum/midias/edc6b2f3-c93b-4a4e-8c4b-baade462310e/PoliticaPrivacidade_institucional.pdf"
            target="_blank" rel="noreferrer">Política de Privacidade.</a></p>
      </CookieNotice>

			<MainNavbar />
			<MDBContainer fluid>
				<MDBRow>
					<MDBCol style={{ paddingLeft: 0, paddingRight: 0 }}>
						{children}
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			<MainFooter />
		</ModalInteresse>
	);
}
